import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ActivitySectionDirective,
  CallWaiterService,
  InvoiceQrcodeImageService,
  RestaurantTableService,
  UserActivityClickTrackingDirective,
  WidgetUiConfigRef,
} from '@core';

@Component({
  selector: 'app-invoice-tab',
  templateUrl: './invoice-tab.component.html',
  imports: [
    TranslateModule,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./invoice-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceTabComponent {

  public readonly table = toSignal(this.restaurantTable.table$);

  public readonly qrUrl = toSignal(this.invoiceQrcodeImage.file$);

  public readonly withCallWaiterRequestBill = toSignal(this.callWaiter.withRequestBill$, {
    initialValue: false,
  });

  public readonly callWaiterMain = toSignal(this.callWaiter.mainStatus$, {
    requireSync: true,
  });

  public readonly callWaiterRequestBill = toSignal(this.callWaiter.requestBillStatus$, {
    requireSync: true,
  });

  public readonly callWaiterAnotherRound = toSignal(this.callWaiter.anotherRoundStatus$, {
    requireSync: true,
  });

  constructor(
    private readonly restaurantTable: RestaurantTableService,
    private readonly invoiceQrcodeImage: InvoiceQrcodeImageService,
    private readonly callWaiter: CallWaiterService,
    public readonly widgetUiConfig: WidgetUiConfigRef,
  ) {}


  handlerCallWaiterToPay($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      $event.preventDefault();
      this.callWaiter.setRequestBill(!this.callWaiterRequestBill());
    }
  }

}
