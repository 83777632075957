<div class="content" coreActivitySection="CallWaiterButtons">
  <img class="logo" [src]="widgetUiConfig.logo" alt=""/>

  @if (withCallWaiterMain()) {
    <button
      [class.active]="callWaiterMain()"
      (click)="handlerCallWaiter($event)"
      [coreUserActivityClickTracking]="callWaiterMain() ? 'CancelCallWaiter' : 'StartCallWaiter'"
    >
      {{ widgetUiConfig.callWaiterButtonName }}
    </button>
  }

  @if (withCallWaiterAnotherRound()) {
    <button
      [class.active]="callWaiterAnotherRound()"
      (click)="handlerCallWaiterToRepeat($event)"
      [coreUserActivityClickTracking]="callWaiterAnotherRound() ? 'CancelCallWaiterToRepeat' : 'StartCallWaiterToRepeat'"
    >
      {{ widgetUiConfig.anotherRoundButtonName }}
    </button>
  }

  @if (table()?.wifiName; as wifiName) {
    <div class="box">
      <div class="box__title">WI-FI connection</div>

      <div class="box__field">
        <span class="box__field__label">Network:</span>
        <span class="box__field__value">{{ wifiName }}</span>
      </div>

      @if (table()?.wifiPassword; as wifiPassword) {
        <div class="box__field">
          <span class="box__field__label">Password:</span>
          <span class="box__field__value">{{ wifiPassword }}</span>
        </div>
      }
    </div>
  }
</div>
