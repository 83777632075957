import { AfterViewInit, Component, DestroyRef, ElementRef, Inject, OnInit, viewChild } from '@angular/core';
import { NavigationStart, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, from, fromEvent, pairwise, switchMap, timer } from 'rxjs';
import { filter, map, skip, tap } from 'rxjs/operators';
import {
  ActivitySectionDirective,
  CallWaiterService,
  CORE_FEATURE_TOGGLE,
  CoreFeatureToggle,
  InteractionService,
  InteractsPresentComponent,
  UserActivityClickTrackingDirective,
  WidgetUiConfigRef,
} from '@core';

import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  imports: [
    RouterLink,
    TranslateModule,
    RouterLinkActive,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
    InteractsPresentComponent,
  ],
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit, AfterViewInit {

  private readonly tabsElement = viewChild<ElementRef<HTMLDivElement>>('tabs');

  public readonly tabIndicator = {
    position: -100,
    accent: false,
  };

  public readonly withCallWaiterMain = toSignal(this.callWaiterService.withMain$, {
    initialValue: false,
  });

  public readonly callWaiterMain = toSignal(this.callWaiterService.mainStatus$, {
    requireSync: true,
  });

  public readonly callWaiterRequestBill = toSignal(this.callWaiterService.requestBillStatus$, {
    requireSync: true,
  });

  public readonly callWaiterAnotherRound = toSignal(this.callWaiterService.anotherRoundStatus$, {
    requireSync: true,
  });

  public readonly withTabBar = this.featureToggle.withTabBar;

  constructor(
    @Inject(CORE_FEATURE_TOGGLE) private readonly featureToggle: CoreFeatureToggle,
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly interaction: InteractionService,
    private readonly widgetUiConfig: WidgetUiConfigRef,
    private readonly callWaiterService: CallWaiterService,
  ) {}

  get widgetUi(): WidgetUiConfigRef {
    return this.widgetUiConfig;
  }

  get hideTabBar(): boolean {
    if (this.widgetUiConfig.tabBarAutoHide) {
      return this.interaction.interacting;
    }
    else {
      return true;
    }
  }

  get tabIndicatorPulse(): boolean {
    return this.tabIndicator.accent && (
      this.callWaiterMain() || this.callWaiterRequestBill() || this.callWaiterAnotherRound()
    );
  }


  get isHomePage(): boolean {
    return this.router.url === '/main/home';
  }

  get isMenuPage(): boolean {
    return this.router.url === '/main/menu';
  }

  get isInvoicePage(): boolean {
    return this.router.url === '/main/invoice';
  }

  get isServicePage(): boolean {
    return this.router.url === '/main/home/service';
  }

  ngOnInit(): void {
    this.initWorkerUserInteracts();
  }

  ngAfterViewInit(): void {
    this.initWorkerIndicatorPosition();
  }

  public handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      console.log($event.target);
      this.callWaiterService.setMain(!this.callWaiterMain());
    }
  }

  public navigateTo(commands: unknown[]): void {
    this.router.navigate(commands);
  }

  private initWorkerIndicatorPosition(): void {
    timer(1000).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.setIndicatorPositionByUrl(this.router.url);
    });

    this.router.events.pipe(
      filter((event): event is NavigationStart => {
        return event instanceof NavigationStart;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((event) => {
      this.setIndicatorPositionByUrl(event.url);
    });

    fromEvent(window, 'resize').pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.setIndicatorPositionByUrl(this.router.url);
    });

    this.widgetUiConfig.config$.pipe(
      map(() => {
        return this.widgetUiConfig.toolbarButtonsCount;
      }),
      distinctUntilChanged(),
      pairwise(),
      filter(([prev, next]) => {
        return prev !== next;
      }),
      switchMap(() => from(this.router.navigate(['/main/home']))),
      switchMap(() => timer(10)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.setIndicatorPositionByUrl(this.router.url);
    });
  }

  private initWorkerUserInteracts(): void {
    this.interaction.idle$.pipe(
      skip(1),
      tap((idle) => {
        if (!idle && this.isHomePage) {
          this.router.navigate(this.featureToggle.idleClickGoTo);
        }
      }),
      filter((idle) => idle),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      if (!this.isHomePage) {
        this.router.navigate(['/main/home']);
      }

      if (this.translate.currentLang !== this.translate.defaultLang) {
        this.translate.use(this.translate.defaultLang);
      }
    });
  }

  private setIndicatorPositionByUrl(url: string): void {
    const element = this.getTabElementByUrl(url);

    if (element) {
      this.tabIndicator.position = element.offsetLeft + (
        element.offsetWidth / 2
      );
      this.tabIndicator.accent = element.hasAttribute('indicatorAccent');
    }
  }

  private getTabElementByUrl(url: string): HTMLElement | null {
    return this.tabsElement()?.nativeElement.querySelector(`.tab[routerlink="${ url }"]`) ?? null;
  }

}
