import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ActivitySectionDirective,
  CallWaiterService,
  RestaurantTableService,
  UserActivityClickTrackingDirective,
  WidgetUiConfigRef,
} from '@core';

@Component({
  selector: 'app-service-centre',
  templateUrl: './service-centre.component.html',
  imports: [
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  styleUrls: ['./service-centre.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCentreComponent {

  public readonly table = toSignal(this.restaurantTable.table$);

  public readonly withCallWaiterMain = toSignal(this.callWaiter.withMain$, {
    initialValue: false,
  });

  public readonly withCallWaiterAnotherRound = toSignal(this.callWaiter.withAnotherRound$, {
    initialValue: false,
  });

  public readonly callWaiterMain = toSignal(this.callWaiter.mainStatus$, {
    initialValue: false,
  });

  public readonly callWaiterAnotherRound = toSignal(this.callWaiter.anotherRoundStatus$, {
    initialValue: false,
  });

  constructor(
    private readonly restaurantTable: RestaurantTableService,
    private readonly callWaiter: CallWaiterService,
    public readonly widgetUiConfig: WidgetUiConfigRef,
  ) {}

  public handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      $event.preventDefault();
      this.callWaiter.setMain(!this.callWaiterMain());
    }
  }

  public handlerCallWaiterToRepeat($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      $event.preventDefault();
      this.callWaiter.setAnotherRound(!this.callWaiterAnotherRound());
    }
  }

}
