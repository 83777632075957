<div class="call-waiter-message"
     [class.active]="callWaiterMain() || callWaiterRequestBill() || callWaiterAnotherRound()">
  {{ 'common.callWaiterMessage' | translate }}
</div>

<div class="content" coreActivitySection="InvoiceButton">
  <img class="logo" [src]="widgetUiConfig.logo" alt=""/>

  @if (qrUrl(); as url) {
    <img class="qr" [src]="url" alt="QR Code">
  }

  <div class="table">{{ table()?.tableName }}</div>

  @if (withCallWaiterRequestBill()) {
    @if (qrUrl()) {
      <div class="divider">OR</div>
    }

    <button
      [class.active]="callWaiterRequestBill()"
      (click)="handlerCallWaiterToPay($event)"
      [coreUserActivityClickTracking]="callWaiterRequestBill() ? 'CancelCallWaiterToPay' : 'StartCallWaiterToPay'"
    >
      {{ widgetUiConfig.requestBillButtonName }}
    </button>
  }
</div>
