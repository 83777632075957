@if (widgetUi.toolbarButtonsCount > 1) {
  <div class="levitation" [class.active]="hideTabBar">
    <div class="tab-bar tab-bar__with-bg" [style.--indicator-position.px]="tabIndicator.position">
      <div class="indicator" [class.accent]="tabIndicator.accent && this.isHomePage" [class.pulse]="tabIndicatorPulse"
           coreActivitySection="TabBarIndicator">
        @if (widgetUi.menuPageEnabled) {
          <div
            class="label"
            [routerLink]="isMenuPage ? '/main/home' : '/main/menu'"
            [class.active]="isMenuPage"
            [coreUserActivityClickTracking]="isMenuPage ? 'MenuPageClose' : 'MenuPageOpen'"
          >
            {{ widgetUi.menuPageName }}
          </div>
        }
        @if (widgetUi.serviceCenterPageEnabled) {
          <div
            class="label" routerLink="/main/home"
            routerLinkActive="active"
            (click)="navigateTo(isServicePage ? ['/main/home'] : ['/main/home/service'])"
            [coreUserActivityClickTracking]="isServicePage ? 'ServicePageClose' : 'ServicePageOpen'"
          >
            <span>{{ widgetUi.serviceCenterPageName }}</span>
          </div>
        }
        @if (withCallWaiterMain() && !widgetUi.serviceCenterPageEnabled) {
          <div
            class="label"
            routerLink="/main/home"
            routerLinkActive="active"
            (click)="handlerCallWaiter($event)"
            [coreUserActivityClickTracking]="callWaiterMain() ? 'CancelCallWaiter' : 'StartCallWaiter'"
          >
            @if (!callWaiterMain()) {
              <span>{{ widgetUi.callWaiterButtonName }}</span>
            }
            @if (callWaiterMain()) {
              <span>{{ 'tabBar.callWaiterCancel' | translate }}</span>
            }
          </div>
        }
        @if (!withCallWaiterMain() && !widgetUi.serviceCenterPageEnabled) {
          <div
            class="label"
            routerLink="/main/home"
            routerLinkActive="active"
            coreUserActivityClickTracking="GoHomePage"
          >
            <img class="logo" [src]="widgetUi.logo" alt=""/>
          </div>
        }
        @if (widgetUi.invoicePageEnabled) {
          <div
            class="label"
            [routerLink]="isInvoicePage ? '/main/home' : '/main/invoice'"
            [class.active]="isInvoicePage"
            [coreUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
          >
            {{ widgetUi.invoicePageName }}
          </div>
        }
      </div>
      <div class="tabs" #tabs coreActivitySection="TabBar">
        @if (widgetUi.menuPageEnabled) {
          <button class="tab" routerLink="/main/menu" routerLinkActive="active"
                  coreUserActivityClickTracking="OpenMenuPage">
            @if (widgetUi.menuPageIcon) {
              <img [src]="widgetUi.menuPageIcon" [alt]="widgetUi.menuPageName"/>
            }
            <span>{{ widgetUi.menuPageName }}</span>
          </button>
        }
        @if (widgetUi.serviceCenterPageEnabled) {
          <button class="tab" indicatorAccent routerLink="/main/home" routerLinkActive="active"
                  coreUserActivityClickTracking="GoHomePage">
            @if (widgetUi.serviceCenterPageIcon) {
              <img [src]="widgetUi.serviceCenterPageIcon" alt=""/>
            }
            <span>{{ widgetUi.serviceCenterPageName }}</span>
          </button>
        }
        @if (withCallWaiterMain() && !widgetUi.serviceCenterPageEnabled) {
          <button
            class="tab" indicatorAccent routerLink="/main/home" routerLinkActive="active"
            coreUserActivityClickTracking="GoHomePage">
            @if (widgetUi.logo) {
              <img [src]="widgetUi.logo" alt=""/>
            }
            <span>{{ widgetUi.callWaiterButtonName }}</span>
          </button>
        }
        @if (!withCallWaiterMain() && !widgetUi.serviceCenterPageEnabled) {
          <button
            class="tab" routerLink="/main/home" routerLinkActive="active" coreUserActivityClickTracking="GoHomePage">
            @if (widgetUi.logo) {
              <img [src]="widgetUi.logo" alt=""/>
            }
          </button>
        }
        @if (widgetUi.invoicePageEnabled) {
          <button class="tab" routerLink="/main/invoice" routerLinkActive="active"
                  coreUserActivityClickTracking="OpenInvoicePage">
            @if (widgetUi.invoicePageIcon) {
              <img [src]="widgetUi.invoicePageIcon" [alt]="widgetUi.invoicePageName"/>
            }
            <span>{{ widgetUi.invoicePageName }}</span>
          </button>
        }
      </div>
    </div>
  </div>
}

@if (widgetUi.toolbarButtonsCount === 1) {
  <div class="levitation" [class.active]="hideTabBar">
    <div class="tab-bar" [style.--indicator-position.%]="85">
      <div class="indicator" [class.accent]="tabIndicator.accent && this.isHomePage" [class.pulse]="tabIndicatorPulse"
           coreActivitySection="TabBarIndicator">
        @if (widgetUi.menuPageEnabled) {
          <div
            class="label"
            routerLink="/main/menu"
            routerLinkActive="active"
            (click)="navigateTo(['main/home'])"
            [coreUserActivityClickTracking]="isMenuPage ? 'MenuPageClose' : 'MenuPageOpen'"
          >
            <img class="logo" [src]="widgetUi.logo" alt=""/>
          </div>
          <div
            class="label"
            routerLink="/main/home"
            routerLinkActive="active"
            (click)="navigateTo(['main/menu'])"
            [coreUserActivityClickTracking]="isMenuPage ? 'MenuPageClose' : 'MenuPageOpen'"
          >
            {{ widgetUi.menuPageName }}
          </div>
        }
        @if (widgetUi.serviceCenterPageEnabled && withTabBar) {
          <div
            class="label" routerLink="/main/home"
            routerLinkActive="active"
            (click)="navigateTo(isServicePage ? ['/main/home'] : ['/main/home/service'])"
            [coreUserActivityClickTracking]="isServicePage ? 'ServicePageClose' : 'ServicePageOpen'"
          >
            {{ widgetUi.serviceCenterPageName }}
          </div>
        }
        @if (withCallWaiterMain() && !widgetUi.serviceCenterPageEnabled) {
          <div
            class="label"
            routerLink="/main/home"
            routerLinkActive="active"
            (click)="handlerCallWaiter($event)"
            [coreUserActivityClickTracking]="callWaiterMain() ? 'CancelCallWaiter' : 'StartCallWaiter'"
          >
            @if (!callWaiterMain()) {
              <span>{{ widgetUi.callWaiterButtonName }}</span>
            }
            @if (callWaiterMain()) {
              <span>{{ 'tabBar.callWaiterCancel' | translate }}</span>
            }
          </div>
        }
        @if (widgetUi.invoicePageEnabled && withTabBar) {
          <div
            class="label"
            routerLink="/main/invoice"
            routerLinkActive="active"
            (click)="navigateTo(['main/home'])"
            [coreUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
          >
            <img class="logo" [src]="widgetUi.logo" alt=""/>
          </div>
          <div
            class="label"
            routerLink="/main/home"
            routerLinkActive="active"
            (click)="navigateTo(['main/invoice'])"
            [coreUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
          >
            {{ widgetUi.invoicePageName }}
          </div>
        }
      </div>
      <div class="tabs tabs__center" #tabs coreActivitySection="TabBar">
        @if (widgetUi.menuPageEnabled && isMenuPage) {
          <button
            class="tab"
            routerLink="/main/menu"
            routerLinkActive="active"
            indicatorAccent
            coreUserActivityClickTracking="OpenMenuPage"
          >
            <img class="logo" [src]="widgetUi.logo" alt=""/>
          </button>
        }
        @if (isHomePage) {
          <button
            class="tab"
            routerLink="/main/home"
            routerLinkActive="active"
            indicatorAccent
            coreUserActivityClickTracking="GoHomePage"
          >
            <img class="logo" [src]="widgetUi.logo" alt=""/>
          </button>
        }
        @if (widgetUi.invoicePageEnabled && isInvoicePage && withTabBar) {
          <button
            class="tab"
            routerLink="/main/invoice"
            routerLinkActive="active"
            indicatorAccent
            coreUserActivityClickTracking="OpenInvoicePage"
          >
            <img class="logo" [src]="widgetUi.logo" alt=""/>
          </button>
        }
      </div>
    </div>
  </div>
}

@if (!hideTabBar) {
  <core-interacts-present/>
}
