@if (isHomePage()) {
  <div class="special-offer-handler" (click)="specialOfferOnClick()"></div>
}

<app-happy-hours/>

<div class="call-waiter-message"
     [class.active]="callWaiterMain() || callWaiterRequestBill() || callWaiterAnotherRound()">
  {{ 'common.callWaiterMessage' | translate }}
</div>

<div [@homeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
