import { Component } from '@angular/core';
import { WidgetUiConfigRef } from '@core';
import { RootComponent } from '@core/lib/root.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    RootComponent,
  ],
  host: {
    '[style.--theme-primary-color]': 'this.widgetUiConfig.primaryColor',
    '[style.--theme-primary-light-color]': 'this.widgetUiConfig.primaryColorLight',
    '[style.--theme-primary-dark-color]': 'this.widgetUiConfig.primaryColorDark',
    '[style.--theme-secondary-color]': 'this.widgetUiConfig.secondaryColor',
    '[style.--theme-secondary-light-color]': 'this.widgetUiConfig.secondaryColorLight',
    '[style.--theme-secondary-dark-color]': 'this.widgetUiConfig.secondaryColorDark',
    '[style.--theme-tertiary-color]': 'this.widgetUiConfig.tertiaryColor',
    '[style.--theme-tertiary-light-color]': 'this.widgetUiConfig.tertiaryColorLight',
    '[style.--theme-tertiary-dark-color]': 'this.widgetUiConfig.tertiaryColorDark',
  }
})
export class AppComponent {

  constructor(
    public readonly widgetUiConfig: WidgetUiConfigRef,
  ) {}

}
